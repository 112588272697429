import firebase from "firebase/compat/app";
import { useEffect, useState } from "react";
import { getCurrentBrowserFingerPrint } from "@rajesh896/broprint.js";
import { addClicked, addLoaded } from "./utils/firebase";

import "./App.css";
import "bootstrap/dist/css/bootstrap.css";

import Game1Img from "./assets/images/game_1.png";
// import Game2Img from "./assets/images/game_2.png";
import Game3Img from "./assets/images/game_3.png";
// import Game4Img from "./assets/images/game_4.png";
 
function App() {
  const [deviceId, setDeviceId] = useState("");
  const gameList = [
    {
      key: "game_1",
      src: Game1Img,
      alt: "",
      link: "https://slotvip-dragon-cloud.web.app/",
    },
    {
      key: "game_3",
      src: Game3Img,
      alt: "วงล้อมังกร",
      link: "https://slotvip-wheellucky-cloud.web.app/",
    },
    
  ];


  useEffect(() => {
    getCurrentBrowserFingerPrint().then((fingerprint) => {
      setDeviceId(fingerprint);
    });
  }, []);

  useEffect(() => {
    if (deviceId) {
      const data = {
        domain: window.location.hostname,
        timestamp: firebase.firestore.FieldValue.serverTimestamp(),
        deviceId: deviceId,
      };
      addLoaded(data);
    }
  }, [deviceId]);

  const GameIcon = ({ src, alt, link }) => {
    return (
      <div
        className="col-6 col-sm-3"
        onClick={() => {
          addClicked({
            link: link,
            timestamp: firebase.firestore.FieldValue.serverTimestamp(),
            deviceId: deviceId,
          });
        }}
      >
        <a href={link} target="_blank" rel="noopener noreferrer">
          <img className="icon-game" src={src} alt={alt} />
        </a>
      </div>
    );
  };

  const GameList = ({ datasource }) => {
    return datasource.map((g) => (
      <GameIcon link={g.link} src={g.src} alt={g.alt} key={g.key} />
    ));
  };

  return (
    <div className="App">
      <div className="content">
        <div className="container">
          <div className="row justify-content-md-center">
            <GameList datasource={gameList} />
          </div>
        </div>
      </div>
    </div>
  );
}

export default App;
