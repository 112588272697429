import firebase from "firebase/compat/app";

import "firebase/compat/storage";
import "firebase/compat/auth";
import "firebase/compat/firestore";

const firebaseConfig = {
  apiKey: "AIzaSyCa2gWhAZxnkHT2hNGt2W-JO52_vqSwz_w",
  authDomain: "jarvis-24webservice.firebaseapp.com",
  databaseURL: "https://jarvis-24webservice-default-rtdb.firebaseio.com",
  projectId: "jarvis-24webservice",
  storageBucket: "jarvis-24webservice.appspot.com",
  messagingSenderId: "149920931937",
  appId: "1:149920931937:web:58238a51ccf16fbaef4a20",
  measurementId: "G-DVN4Z4TV3P",
};

const app = firebase.initializeApp(firebaseConfig);

export async function addLoaded(data) {
  await app
    .firestore()
    .collection("t_landingpage")
    .doc("landing-page-cal5")
    .collection("load_data")
    .add({
      ...data,
    });
}

export async function addClicked(data) {
  await app
    .firestore()
    .collection("t_landingpage")
    .doc("landing-page-cal5")
    .collection("click_data")
    .add({
      ...data,
    });
}
